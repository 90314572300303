import { z } from 'zod';

import authService from './auth/auth';
import { EnumJobType, EnumLevelLanguage } from './candidateProfile';
import { DjangoDataZodSchema } from './common';
import { apiRoutes } from './config';
import { resumeAnalyzerSchema } from './resume-checker';

const resumeListItemSchema = z.object({
  id: z.number(),
  name: z.string(),
  updatedAt: z.coerce.date(),
  resumeScore: z.number(),
  generalStatus: z.string(),
  scoreStatus: z.string(),
});

const resumeDetailSchema = z.object({
  generalStatus: z.string(),
  id: z.number(),
  resumeName: z.string(),
  name: z.string(),
  jobTitle: z.string(),
  phoneNumber: z.string(),
  email: z.string(),
  linkedinUrl: z.string(),
  address: z.string(),
  summary: z.string(),
  hobbies: z.array(z.string()),
  education: z.array(
    z.object({
      id: z.number().optional(),
      schoolName: z.string(),
      address: z.string(),
      degree: z.string(),
      major: z.string(),
      gpa: z.string(),
      startDate: z.string(),
      endDate: z.string(),
      description: z.string(),
    })
  ),
  languages: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      level: z.nativeEnum(EnumLevelLanguage),
    })
  ),
  certificates: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      url: z.string(),
      date: z.string(),
      description: z.string(),
    })
  ),
  workExperience: z.array(
    z.object({
      id: z.number().optional(),
      company: z.string(),
      jobTitle: z.string(),
      description: z.string(),
      address: z.string(),
      technologies: z.array(z.string()),
      industry: z.string(),
      startDate: z.string(),
      endDate: z.string(),
      projects: z.array(
        z.object({
          id: z.number(),
          name: z.string(),
          description: z.string(),
          client: z.string(),
          url: z.string(),
          technologies: z.array(z.string()),
          industry: z.string(),
          startDate: z.string(),
          endDate: z.string(),
        })
      ),
      jobType: z.nativeEnum(EnumJobType),
    })
  ),
  skills: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      level: z.string(),
      experience: z.number(),
      aiGenerated: z.boolean(),
      group: z.string(),
    })
  ),
  extraActivities: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      description: z.string(),
      startDate: z.string(),
      endDate: z.string(),
      oneDayEvent: z.boolean(),
    })
  ),
  links: z.array(
    z.object({
      id: z.number(),
      label: z.string(),
      url: z.string(),
    })
  ),
  projects: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      description: z.string(),
      url: z.string(),
      startDate: z.string(),
      endDate: z.string(),
      technologies: z.array(z.string()),
    })
  ),
});

type ResumeDetailApi = z.infer<typeof resumeDetailSchema>;

export const transformBuilderApiData = ({
  name,
  phoneNumber,
  address,
  email,
  ...data
}: ResumeDetailApi) => {
  return {
    ...data,
    personalInformation: {
      name,
      phoneNumber,
      address,
      email,
    },
    links: {
      githubUrl: data.links.find(
        ({ label }) => label.toLowerCase() === 'github'
      )?.url,
      githubId: data.links.find(({ label }) => label.toLowerCase() === 'github')
        ?.id,
      linkedinUrl: data.links.find(
        ({ label }) => label.toLowerCase() === 'linkedin'
      )?.url,
      linkedinId: data.links.find(
        ({ label }) => label.toLowerCase() === 'linkedin'
      )?.id,
      links: data.links.filter(
        ({ label }) =>
          label.toLowerCase() !== 'github' && label.toLowerCase() !== 'linkedin'
      ),
    },
  };
};

export type ResumeDetail = ReturnType<typeof transformBuilderApiData>;

const trasnformBuilderData = ({
  personalInformation,
  ...data
}: Partial<ResumeDetail>) => {
  return {
    ...data,
    ...personalInformation,
    languages: data.languages?.map(({ id, level, name }) => ({
      name: name || '',
      level: level || '',
      id,
    })),
    links: data.links && [
      { label: 'Github', url: data.links?.githubUrl, id: data.links.githubId },
      {
        label: 'LinkedIn',
        url: data.links?.linkedinUrl,
        id: data.links.linkedinId,
      },
      ...data.links.links.map(({ label, url, id }) => ({
        id,
        label: label ?? '',
        url: url ?? '',
      })),
    ],
  };
};
export const getResumeList = async () => {
  const { data } = await authService.get(apiRoutes.resumeBuilder._root);
  return DjangoDataZodSchema(resumeListItemSchema).parse(data);
};

export const getResumeDetail = async (id: number) => {
  const { data } = await authService.get(
    apiRoutes.resumeBuilder.detail(id)._root
  );
  const validatedData = resumeDetailSchema.parse(data);
  return transformBuilderApiData(validatedData);
};

export const postResume = (cvFile: Blob | File) => {
  return authService.post(
    apiRoutes.resumeBuilder.fromFile,
    {
      cvFile,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export const getResumeScore = async (id: number) => {
  const { data } = await authService.get(
    apiRoutes.resumeBuilder.detail(id).score
  );
  return resumeAnalyzerSchema.parse(data);
};

export const patchResume = async ({
  data,
  id,
}: {
  id: number;
  data: Partial<ResumeDetail>;
}) => {
  return authService.patch(
    apiRoutes.resumeBuilder.detail(id)._root,
    trasnformBuilderData(data)
  );
};

export const deleteResume = async (id: number) => {
  return authService.delete(apiRoutes.resumeBuilder.detail(id)._root);
};
