import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  getResumeDetail,
  getResumeList,
  getResumeScore,
} from '@/services/builder';

export const resumeBuilder = createQueryKeys('resumeBuilder', {
  list: {
    queryFn: getResumeList,
    queryKey: null,
  },
  detail: (id: number) => ({
    queryFn: () => getResumeDetail(id),
    queryKey: [id],
    contextQueries: {
      score: {
        queryFn: () => getResumeScore(id),
        queryKey: [id],
      },
    },
  }),
});
